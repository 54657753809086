import type {
  ChannelType,
  Institution,
  MessageAction,
  Plan,
  ReportCreationOrigin,
  ReportJustification,
  ReportSourceType,
} from '@faceup/utils'
import posthog from 'posthog-js'
import { useCallback } from 'react'
import type { ReportPriority, ReportStatusType } from '../__generated__/globalTypes'
import { useMotherId } from '../hooks/useMotherId'
import { useGlobalInfo } from '../locales'

/*
  Suffixes for event tracking:
  - Created
  - Changed
  - Opened
*/

const ALLOW_TRACKING = import.meta.env.VITE_ENVIRONMENT === 'production'

if (import.meta.env.VITE_POSTHOG_TOKEN) {
  posthog.init(import.meta.env.VITE_POSTHOG_TOKEN, {
    api_host: 'https://eu.i.posthog.com',
    autocapture: false,
    session_recording: {
      maskAllInputs: true,
      maskTextSelector: '.sensitive',
    },
  })
}

// this function is the only function, which we need to be outside of the hook, because it is used in the `CustomApolloProvider.tsx`
export const reset = () => posthog.reset()

const useAnalytics = () => {
  const {
    viewerId,
    institution,
    realInstitution,
    motherInstitutionName,
    employees,
    subscriptionStatus,
    plan,
    institutionUuid,
  } = useGlobalInfo()
  const { getMotherIdWithNull } = useMotherId()
  const motherIdWithNull = getMotherIdWithNull()

  const trackPageView = () => {
    if (realInstitution === null || realInstitution === true) {
      posthog.capture('$pageview')
    }
  }

  const identify = (name: string, email: string) => {
    if (!realInstitution) {
      return
    }
    if (viewerId) {
      posthog.identify(viewerId, { email, name })
    }
    if (institutionUuid && motherInstitutionName) {
      posthog.group('institution', institutionUuid, {
        name: motherInstitutionName,
        plan: plan?.toLowerCase(),
        type: institution?.toLowerCase(),
        status: subscriptionStatus?.toLowerCase(),
        size: employees,
      })
    }
  }

  // settings
  const trackMemberCreated = () => posthog.capture('Member created')
  const trackMemberInvitationCreated = () => posthog.capture('Member invitation created')
  const trackOrganizationalUnitCreated = (level: number) =>
    posthog.capture('Organizational unit created', { level })
  const trackInstitutionFromPartnerCreated = (type: Institution) =>
    posthog.capture('Institution from partner created', { type: type.toLowerCase() })

  // sign up/login tracking
  const trackLogin = () => posthog.capture('Login')
  const trackSignUpProgress = (step: number, type: 'purchase' | 'trial') =>
    posthog.capture('Sign Up progress', { step, type: type.toLowerCase() })
  const trackSignUp = (institution: Institution | 'Partner', type: 'purchase' | 'trial') =>
    posthog.capture('Sign Up', {
      institution: institution.toLowerCase(),
      type: type.toLowerCase(),
    })

  // report tracking
  const trackMessageOpened = () => posthog.capture('Message opened')
  const trackMessageCreated = () => posthog.capture('Message created')
  const trackInternalCommentCreated = () => posthog.capture('Internal comment created')
  const trackReportOpened = (source: ReportSourceType, created: ReportCreationOrigin) =>
    posthog.capture('Report opened', {
      source: source.toLowerCase(),
      created: created.toLowerCase(),
    })

  // report
  const trackReportCreated = () => posthog.capture('Report created')
  const trackReportAssignedMemberChanged = () => posthog.capture('Report assigned member changed')
  const trackReportPriorityChanged = (priority: ReportPriority) =>
    posthog.capture('Report priority changed', { priority: priority.toLowerCase() })
  const trackReportLabelChanged = () => posthog.capture('Report label changed')
  const trackReportJustificationChanged = (justification: ReportJustification) =>
    posthog.capture('Report justification changed', { justification: justification.toLowerCase() })
  const trackReportDeadlineChanged = () => posthog.capture('Report deadline changed')
  const trackReportStatusChanged = (status: ReportStatusType) =>
    posthog.capture('Report status changed', { status: status.toLowerCase() })
  const trackReportRedaction = (status: 'started' | 'completed') =>
    posthog.capture('Report redaction', { status })

  // channel tracking
  const trackChannelCreated = (type: ChannelType) =>
    posthog.capture('Channel created', { type: type.toLowerCase() })

  // billing (chargebee)
  const trackBuyActionClicked = () => posthog.capture('Buy action clicked')
  const trackCheckoutStarted = (plan: Plan) =>
    posthog.capture('Checkout started', { plan: plan.toLowerCase() })
  const trackPurchaseCompleted = (plan: Plan) =>
    posthog.capture('Purchase completed', { plan: plan.toLowerCase() })

  // ai assistant
  const trackAiAssistantTextHelperOpened = () => posthog.capture('AI assistant text helper opened')
  const trackAiAssistantTextHelper = (action: MessageAction) =>
    posthog.capture('AI assistant text helper changed', { action: action.toLowerCase() })

  const analytics = {
    // biome-ignore lint/correctness/useExhaustiveDependencies(realInstitution):
    trackPageView: useCallback(trackPageView, [realInstitution]),
    // biome-ignore lint/correctness/useExhaustiveDependencies(employees):
    // biome-ignore lint/correctness/useExhaustiveDependencies(institution):
    // biome-ignore lint/correctness/useExhaustiveDependencies(motherIdWithNull):
    // biome-ignore lint/correctness/useExhaustiveDependencies(motherInstitutionName):
    // biome-ignore lint/correctness/useExhaustiveDependencies(plan):
    // biome-ignore lint/correctness/useExhaustiveDependencies(realInstitution):
    // biome-ignore lint/correctness/useExhaustiveDependencies(subscriptionStatus):
    // biome-ignore lint/correctness/useExhaustiveDependencies(viewerId):
    identify: useCallback(identify, [
      employees,
      institution,
      motherIdWithNull,
      motherInstitutionName,
      plan,
      realInstitution,
      subscriptionStatus,
      viewerId,
    ]),
    reset: useCallback(reset, []),
    trackMemberCreated: useCallback(trackMemberCreated, []),
    trackMemberInvitationCreated: useCallback(trackMemberInvitationCreated, []),
    trackOrganizationalUnitCreated: useCallback(trackOrganizationalUnitCreated, []),
    trackInstitutionFromPartnerCreated: useCallback(trackInstitutionFromPartnerCreated, []),
    trackLogin: useCallback(trackLogin, []),
    trackSignUpProgress: useCallback(trackSignUpProgress, []),
    trackSignUp: useCallback(trackSignUp, []),
    trackMessageOpened: useCallback(trackMessageOpened, []),
    trackMessageCreated: useCallback(trackMessageCreated, []),
    trackInternalCommentCreated: useCallback(trackInternalCommentCreated, []),
    trackReportOpened: useCallback(trackReportOpened, []),
    trackReportCreated: useCallback(trackReportCreated, []),
    trackReportAssignedMemberChanged: useCallback(trackReportAssignedMemberChanged, []),
    trackReportPriorityChanged: useCallback(trackReportPriorityChanged, []),
    trackReportLabelChanged: useCallback(trackReportLabelChanged, []),
    trackReportJustificationChanged: useCallback(trackReportJustificationChanged, []),
    trackReportDeadlineChanged: useCallback(trackReportDeadlineChanged, []),
    trackReportStatusChanged: useCallback(trackReportStatusChanged, []),
    trackReportRedaction: useCallback(trackReportRedaction, []),
    trackChannelCreated: useCallback(trackChannelCreated, []),
    trackBuyActionClicked: useCallback(trackBuyActionClicked, []),
    trackCheckoutStarted: useCallback(trackCheckoutStarted, []),
    trackPurchaseCompleted: useCallback(trackPurchaseCompleted, []),
    trackAiAssistantTextHelperOpened: useCallback(trackAiAssistantTextHelperOpened, []),
    trackAiAssistantTextHelper: useCallback(trackAiAssistantTextHelper, []),
  }

  if (ALLOW_TRACKING) {
    return analytics
  }

  const logger = (name: string, ...args: unknown[]) => console.info(`[Analytics]: ${name}`, args)

  const mockAnalytics = Object.keys(analytics).reduce(
    (acc, key) => ({
      ...acc,
      // biome-ignore lint/correctness/useHookAtTopLevel:
      [key]: useCallback((...args: unknown[]) => logger(key, args), [key]),
    }),
    {} as Record<keyof typeof analytics, () => void>
  )

  return mockAnalytics
}

export default useAnalytics
