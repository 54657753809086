import { gql, useApolloClient, useQuery } from '@apollo/client'
import { useMotherId } from '@faceup/institution'
import { sharedMessages, useIntl } from '@faceup/localization'
import { notification } from '@faceup/ui-base'
import { Feature, FeatureType, Institution, parseChargebeeFeatureValue } from '@faceup/utils'
import {
  type ChargebeeSubscriptionStatus,
  type Country,
  type GlobalQueryInfo,
  type GlobalQueryInfoVariables,
  type PartnerDataQuery,
  type PartnerDataQueryVariables,
  Plan,
} from '../__generated__/globalTypes'
import { useAuth } from './auth'

type GlobalQueryInfo_memberViewer_keys_permissions = NonNullable<
  NonNullable<GlobalQueryInfo['memberViewer']>['keys']
>['permissions'][number]

const GlobalInfoFragments = {
  member: gql`
    fragment GlobalInfo_member on Member {
      id
      country
      isPartner
      __typename
      mother(motherId: $motherId) {
        id
        uuid
        name
        type
        realInstitution
        editable
        countOfMembers
        countOfForms
        isUkgProEnabled
        isUkgReadyEnabled
        isBambooHrEnabled

        billing {
          id
          plan
          employees
          subscriptionStatus
          entitlements {
            id
            featureId
            enabled
            value
            type
          }
        }

        config {
          id
        }

        partner {
          id
          config {
            id
          }
        }
      }

      keys {
        id
        permissions(motherId: $motherId) {
          id
          type
          enabled
          additionalData {
            id
            categoryIds
          }
        }
      }
    }
  `,
}

export const globalInfoQuery = gql`
  query GlobalQueryInfo($motherId: CompanyGlobalId!) {
    memberViewer {
      id
      legacyId
      ...GlobalInfo_member
    }
  }
  ${GlobalInfoFragments.member}
`

const query = {
  partner: gql`
    query PartnerDataQuery {
      memberViewer {
        id
        partner {
          id
          config {
            id
          }
        }
      }
    }
  `,
}

type GlobalInfo = {
  viewerId: string | null
  legacyId: string | null
  viewerType: 'Member' | null
  configId: string | null
  usePaidVersion: boolean | null
  plan: Plan | null
  institution: Institution | null
  motherInstitutionName: string | null
  institutionUuid: string | null
  country: Country | null
  permissions: (GlobalQueryInfo_memberViewer_keys_permissions | null)[]
  realInstitution: boolean | null
  editableMother: boolean | null
  partnerId?: string | null
  entitlements: {
    id: Feature
    isEnabled: boolean
    value: ReturnType<typeof parseChargebeeFeatureValue>
  }[]
  membersCount: number | null
  employees: number | null
  subscriptionStatus: ChargebeeSubscriptionStatus | null
  formsCount: number | null
  integratedApps: {
    isUkgProEnabled: boolean
    isUkgReadyEnabled: boolean
    isBambooHrEnabled: boolean
  } | null
}

/**
 * This hook caused few subtle bugs in the past, so maybe you might want to avoid or improve it.
 */
export const useGlobalInfo = (): GlobalInfo => {
  const client = useApolloClient()
  const { getMotherId, getMotherIdWithNull } = useMotherId()
  const { formatMessage } = useIntl()
  const auth = useAuth()

  const { data: partnerWithoutMotherData } = useQuery<PartnerDataQuery, PartnerDataQueryVariables>(
    query.partner,
    {
      fetchPolicy: 'cache-first',
      skip: !auth.isAuthenticated() || Boolean(getMotherIdWithNull()),
    }
  )

  // tl;dr: the app still works the same but now it's more obvious how it works:
  // This looks weird (and it probably is), but I moved part of AppRouterQuery to UserContext (the part which
  // doesn't need motherId) and got rid of AppRouterQuery_partner (it wasn't really necessary, because both
  // memberViewer and partnerViewer return MemberType and both work for members and partners) but this hook
  // still needs the data from original AppRouterQuery and I didn't want to mess with this hook so I just moved
  // the AppRouterQuery here and then noticed it's the same as the GlobalQuery so I removed it but kept the
  // implementation as it was. I think it can be improved with correct fetchPolicy but I'll leave it for later.
  // Feel free to tinker with it.
  useQuery<GlobalQueryInfo, GlobalQueryInfoVariables>(globalInfoQuery, {
    variables: {
      // It's ugly because same component is used in partner administration
      motherId: getMotherIdWithNull() ?? '',
    },
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    skip: !getMotherIdWithNull() || !auth.isAuthenticated(),
  })

  if (getMotherIdWithNull()) {
    const partnerData = client.readQuery<GlobalQueryInfo, GlobalQueryInfoVariables>({
      query: globalInfoQuery,
      variables: { motherId: getMotherId() },
    })

    const viewer = partnerData?.memberViewer
    if (viewer?.mother) {
      const mother = viewer.mother

      return {
        viewerId: viewer.id,
        legacyId: viewer.legacyId,
        viewerType: viewer.__typename,
        usePaidVersion: mother.billing?.plan !== Plan.Free,
        configId: mother.config.id ?? mother.partner?.config?.id,
        plan: mother.billing?.plan ?? null,
        institution: mother.type ?? Institution.Company,
        motherInstitutionName: mother.name ?? null,
        institutionUuid: mother.uuid,
        country: viewer.country,
        permissions: viewer.keys?.permissions ?? [],
        realInstitution: Boolean(mother.realInstitution),
        editableMother: Boolean(mother.editable),
        partnerId: mother.partner?.id ?? null,
        entitlements: (mother.billing?.entitlements ?? []).map(feature => ({
          id: Feature[feature.featureId],
          isEnabled: feature.enabled,
          value: parseChargebeeFeatureValue(FeatureType[feature.type], feature.value),
        })),
        membersCount: mother.countOfMembers ?? null,
        employees: mother.billing?.employees ?? null,
        subscriptionStatus: mother.billing?.subscriptionStatus ?? null,
        formsCount: mother.countOfForms,
        integratedApps: {
          isUkgProEnabled: mother.isUkgProEnabled ?? false,
          isUkgReadyEnabled: mother.isUkgReadyEnabled ?? false,
          isBambooHrEnabled: mother.isBambooHrEnabled ?? false,
        },
      }
    }
  }

  return {
    viewerId: null,
    legacyId: null,
    viewerType: null,
    usePaidVersion: null,
    configId: partnerWithoutMotherData?.memberViewer?.partner?.config?.id ?? null,
    plan: null,
    institution: null,
    motherInstitutionName: null,
    institutionUuid: null,
    country: null,
    permissions: [],
    realInstitution: null,
    editableMother: null,
    partnerId: null,
    entitlements: [],
    membersCount: null,
    employees: null,
    subscriptionStatus: null,
    formsCount: null,
    integratedApps: null,
  }
}
