import { gql, useMutation } from '@apollo/client'
import type { ModalProps } from '@faceup/ui'
import { notification } from '@faceup/ui-base'
import { omitNullInArray } from '@faceup/utils'
import type { JSX } from 'react'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import type {
  EditMemberModal_institution,
  EditMemberModal_member,
  EditMemberModal_memberToEdit,
  EditMemberMutation,
  EditMemberMutationVariables,
  PermissionType,
} from '../../__generated__/globalTypes'
import { useMotherId } from '../../providers'
import {
  AbstractMemberModal,
  AbstractMemberModalFragments,
  type AccessKey,
  accesses,
} from './AbstractMemberModal'

const messages = defineMessages({
  title: 'Administration.settings.editUser.title',
  cantEditAccountOwner: 'Administration.settings.permissions.cantEditAccountOwner',
})

export const EditMemberModalFragments = {
  EditMemberModal_member: gql`
    fragment EditMemberModal_member on Member {
      id
      isAccountOwner(motherId: $motherId)

      ...AbstractMemberModal_member
    }

    ${AbstractMemberModalFragments.AbstractMemberModal_member}
  `,
  EditMemberModal_memberToEdit: gql`
    fragment EditMemberModal_memberToEdit on Member {
      id
      isAccountOwner(motherId: $motherId)
      name
      phone
      companyIds(motherId: $motherId)
      email
      mother(motherId: $motherId) {
        id
        config {
          id
          reportCategories {
            id
          }
        }
      }
      keys {
        permissions(motherId: $motherId) {
          type
          enabled
          additionalData {
            categoryIds
          }
        }
      }
    }
  `,
  EditMemberModal_institution: gql`
    fragment EditMemberModal_institution on Company {
      id
      ...AbstractMemberModal_institution
    }
    ${AbstractMemberModalFragments.AbstractMemberModal_institution}
  `,
}

const mutations = {
  EditMember: gql`
    mutation EditMemberMutation($input: EditMemberInput!, $motherId: CompanyGlobalId!) {
      editMember(input: $input) {
        member {
          id
          name
          companyIds(motherId: $motherId)
        }
      }
    }
  `,
}

type EditMemberModalProps = {
  // null for kredenc
  member: EditMemberModal_member | null
  institution: EditMemberModal_institution
  memberToEdit: EditMemberModal_memberToEdit
  onCompleted: () => void
} & Required<Pick<ModalProps, 'opened' | 'onClose'>>

export const EditMemberModal = ({
  opened,
  member,
  institution,
  onClose,
  memberToEdit,
  onCompleted,
}: EditMemberModalProps) => {
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()

  const isKredenc = !member

  const [editMember] = useMutation<EditMemberMutation, EditMemberMutationVariables>(
    mutations.EditMember,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
      onCompleted,
    }
  )

  const companyIds = omitNullInArray(memberToEdit.companyIds)

  const defaultValues = {
    email: memberToEdit.email ?? '',
    institutionIds: companyIds,
    permissions: memberToEdit.keys.permissions,
  }

  return (
    <AbstractMemberModal
      modalVariant='edit'
      title={<FormattedMessage {...messages.title} />}
      member={member}
      institution={institution}
      opened={opened}
      onClose={onClose}
      overrideDisabledMessageForType={(
        permissionType: PermissionType,
        originalMessage: JSX.Element | null
      ) => {
        if (
          accesses.settings.subItems?.map(({ type }) => type).includes(permissionType) &&
          memberToEdit.isAccountOwner
        ) {
          return <FormattedMessage {...messages.cantEditAccountOwner} />
        }

        return originalMessage
      }}
      overrideDisabledMessageForSection={(
        section: AccessKey,
        originalMessage: JSX.Element | null
      ) => {
        if (section === 'settings' && memberToEdit.isAccountOwner) {
          return <FormattedMessage {...messages.cantEditAccountOwner} />
        }

        return originalMessage
      }}
      defaultValues={defaultValues}
      onSubmit={async values => {
        const result = await editMember({
          variables: {
            motherId: getMotherId(),
            input: {
              companyIds: values.institutionIds,
              permissions: values.permissions,
              motherId: getMotherId(),
              memberId: memberToEdit.id,
              name: memberToEdit.name ?? '',
              ...(isKredenc && { email: values.email }),
            },
          },
        })

        if (result.errors) {
          return false
        }

        onClose()
        return true
      }}
    />
  )
}
