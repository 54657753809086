import { useLanguageName } from '@faceup/localization'
import type { Language } from '@faceup/utils'
import { useMemo } from 'react'
import type { FieldValues } from 'react-hook-form'
import { Select, type SelectProps } from './..'

export type LanguageSelectProps<T extends FieldValues> = Omit<SelectProps<T>, 'options'> & {
  allowedLanguages?: Language[]
}

export const LanguageSelect = <T extends FieldValues>({
  allowedLanguages,
  ...props
}: LanguageSelectProps<T>) => {
  const { getLanguageList } = useLanguageName()

  const languages = getLanguageList(allowedLanguages)

  const options = useMemo(
    () =>
      languages.map(({ language, name }) => ({
        label: name,
        value: language,
      })),
    [languages]
  )

  return <Select {...props} options={options} />
}
