import { UntitledIcon } from '@faceup/icons'
import { ulChevronDown } from '@faceup/icons/ulChevronDown'
import { Text } from '@faceup/ui'
import {
  type Region,
  SupportedRegion,
  convertRegionToSupportedRegion,
  convertSupportedRegionToRegion,
} from '@faceup/utils'
import { Group, Menu, UnstyledButton } from '@mantine/core'
import { FormattedMessage, defineMessages } from '../TypedIntl'

const messages = defineMessages({
  hostingRegion: 'Administration.registration.fillInfo.step3.hostingRegion',
})

const regionMessages = defineMessages<SupportedRegion>({
  [SupportedRegion.EuWest1]: 'Administration.selectRegion.region.euWest1',
  [SupportedRegion.UsWest1]: 'Administration.selectRegion.region.usWest1',
  [SupportedRegion.MeCentral1]: 'Administration.selectRegion.region.meCentral1',
})

type SelectRegionProps = {
  value: Region
  onChange: (value: Region) => void
}

export const SelectRegion = (props: SelectRegionProps) => {
  const { value, onChange } = props
  return (
    <Text color='subtleText'>
      <FormattedMessage {...messages.hostingRegion} />{' '}
      <Menu>
        <Menu.Target>
          <UnstyledButton>
            <Group spacing='0.375rem'>
              <Text weight='bold'>
                <FormattedMessage {...regionMessages[convertRegionToSupportedRegion(value)]} />
              </Text>{' '}
              <Text color='subtleText'>
                <UntitledIcon icon={ulChevronDown} />
              </Text>
            </Group>
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
          {Object.values(SupportedRegion).map(region => (
            <Menu.Item
              key={region}
              onClick={() => onChange(convertSupportedRegionToRegion(region))}
            >
              <FormattedMessage {...regionMessages[region]} />
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </Text>
  )
}
