import { gql, useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { Link } from '@faceup/router'
import { Button, Card, Form, TableEnhanced } from '@faceup/ui'
import { Checkbox, Input, LoadingOutlined, Space, Typography, notification } from '@faceup/ui-base'
import { isEmail } from '@faceup/utils'
import { Stack, UnstyledButton } from '@mantine/core'
import { useState } from 'react'
import { PasswordInputWithIcon } from '../../Components/PasswordInput'
import RegisterLink from '../../Components/RegisterLink'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import type {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from '../../__generated__/globalTypes'
import { mq } from '../../constants'
import useConfigForProject from '../../hooks/useConfigForProject'
import useRegion from '../../utils/useRegion'
import PageTemplateUnlogged from '../PageTemplateUnlogged'
import mailSent from './assets/email-icon.svg'
import { membersMock, mockCredentialTableColumns, teachersMock } from './loginMockUsers'
import useLogin from './useLogin'

const { Title: AntTitle } = Typography

const messages = defineMessages({
  title: 'Administration.login.title',
  signUp: 'Administration.login.login.signUp',
  forgottenPassword: 'Administration.login.forgottenPassword',
  loginError: 'Administration.login.login.error',
  login: 'Administration.login',
  forgottenPasswordTitle: 'Administration.login.forgottenPassword.title',
  forgottenPasswordLabel: 'Administration.login.forgottenPassword.label',
  forgottenPasswordDone: 'Administration.login.forgottenPassword.done',
  forgottenPasswordTitleDone: 'Administration.login.forgottenPassword.titleDone',
  forgottenPasswordSend: 'Administration.login.forgottenPassword.send',
  backToLogin: 'Administration.login.forgottenPassword.backToLogin',
  twoFactorAuthenticationTitle: 'Administration.login.twoFactorAuthentication.title',
  twoFactorAuthenticationDescription: 'Administration.login.twoFactorAuthentication.description',
  codeLabel: 'Administration.login.2FA.label',
  codeError: 'Administration.login.2FA.error',
  rememberMe: 'Administration.login.rememberMe',
  switchToSSO: 'Administration.login.login.switch',
})

const mutation = {
  resetPassword: gql`
    mutation ResetPasswordMutation($input: ForgottenUserPasswordInput!) {
      forgottenUserPassword(input: $input) {
        success
      }
    }
  `,
}

export type FormType = 'login' | 'forgot-password' | 'forgot-password-sent' | '2fa'
const Login = () => {
  const { formatMessage } = useIntl()
  const { supportEmail } = useConfigForProject()

  const [loading, setLoading] = useState(false)
  const { discoverByEmail } = useRegion()

  const [forgotPasswordEmail, setForgotPasswordEmail] = useState({ value: '', error: false })
  const [showTestAccounts, setShowTestAccounts] = useState(false)
  const {
    serverLoginFormError,
    login,
    code,
    setCode,
    email,
    setEmail,
    password,
    setPassword,
    formType,
    setFormType,
    rememberMe,
    setRememberMe,
  } = useLogin(setLoading)

  const [resetPassword] = useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    mutation.resetPassword,
    {
      onCompleted: () => {
        setLoading(false)
        setFormType('forgot-password-sent')
      },
      onError: error => {
        setLoading(false)
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
    }
  )

  const hasLoginFormValidationFailed = () => {
    let hasFailed = false

    const isValidPassword = Boolean(password.value)
    if (!isValidPassword) {
      setPassword({ ...password, error: true })
      hasFailed = true
    }

    const isValidEmail = isEmail(email.value.trim())
    if (!isValidEmail) {
      setEmail({ ...email, error: true })
      hasFailed = true
    }

    return hasFailed
  }

  const has2FAFormValidationFailed = () => {
    const isCodeValid = code.value.split(' ').join('').length === 6
    if (!isCodeValid) {
      setCode({ ...code, error: true })

      return true
    }

    return false
  }

  const hasForgotPasswordFormValidationFailed = () => {
    const isValidEmail = isEmail(forgotPasswordEmail.value.trim())

    if (!isValidEmail) {
      setForgotPasswordEmail({ ...forgotPasswordEmail, error: true })
      return true
    }
    return false
  }

  const toggleTestAccounts = () => {
    if (
      import.meta.env.VITE_ENVIRONMENT === 'stage' ||
      import.meta.env.VITE_ENVIRONMENT === 'development'
    ) {
      setShowTestAccounts(!showTestAccounts)
    }
  }

  return (
    <PageTemplateUnlogged
      isFooterShown={formType === 'login' || formType === '2fa'}
      contentUnderCard={
        <>
          {(formType === 'login' || formType === 'forgot-password') && (
            <RegisterBox>
              <RegisterLink />
            </RegisterBox>
          )}
          {showTestAccounts && (
            <Stack mt='100px' spacing='100px'>
              <Card title='FaceUp for Schools' hasNoPadding>
                <TableEnhanced
                  pagination={{ defaultPageSize: 20 }}
                  dataSource={teachersMock}
                  columns={mockCredentialTableColumns}
                  rowKey='email'
                />
              </Card>
              <Card title='FaceUp for Companies' hasNoPadding>
                <TableEnhanced
                  pagination={{ defaultPageSize: 20 }}
                  dataSource={membersMock}
                  columns={mockCredentialTableColumns}
                  rowKey='email'
                />
              </Card>
            </Stack>
          )}
        </>
      }
    >
      {formType === 'login' ? (
        <>
          <Title data-cy='login-title' level={3}>
            <FormattedMessage {...messages.title} />
          </Title>
          <Form
            onSubmit={() => {
              if (hasLoginFormValidationFailed() || loading) {
                return
              }

              login()
            }}
          >
            <Form.Item
              label={<FormattedMessage {...sharedMessages.emailLabel} />}
              data-cy='email-group'
              errorMessage={
                email.error && <FormattedMessage {...sharedMessages.invalidEmailError} />
              }
            >
              <Input
                size='large'
                type='email'
                autoComplete='email'
                value={email.value}
                onChange={({ target: { value } }) => setEmail({ value, error: false })}
              />
            </Form.Item>

            <Form.Item
              label={
                <LabelWrapper>
                  <div onDoubleClick={() => toggleTestAccounts()}>
                    <FormattedMessage {...sharedMessages.passwordLabel} />
                  </div>
                </LabelWrapper>
              }
              secondary={
                <UnstyledButton
                  sx={theme => ({
                    color: theme.colors.primary[7],
                  })}
                  data-cy='forgot-password'
                  onClick={() => {
                    setForgotPasswordEmail({ value: email.value, error: false })
                    setFormType('forgot-password')
                  }}
                  tabIndex={-1}
                >
                  <FormattedMessage {...messages.forgottenPassword} />
                </UnstyledButton>
              }
              data-cy='password-group'
              errorMessage={
                (password.error || serverLoginFormError) && (
                  <>
                    <FormattedMessage {...messages.loginError} />{' '}
                    <Link to={routes => routes.registerTrialRequest()}>
                      <FormattedMessage {...messages.signUp} />
                    </Link>
                  </>
                )
              }
            >
              <PasswordInputWithIcon
                autoComplete='current-password'
                size='large'
                onChange={({ target: { value } }) => setPassword({ value, error: false })}
              />
            </Form.Item>
            <Form.Item>
              <Checkbox
                data-cy='remember-me'
                onChange={e => setRememberMe(e.target.checked)}
                defaultChecked={rememberMe}
              >
                <FormattedMessage {...messages.rememberMe} />
              </Checkbox>
            </Form.Item>

            <Button type='submit' data-cy='submit-button' isFullWidth>
              {loading && <LoadingOutlined />} <FormattedMessage {...messages.login} />
            </Button>
            <SSOPageSwitch>
              <Link to={routes => routes.loginSso()}>
                <FormattedMessage {...messages.switchToSSO} />
              </Link>
            </SSOPageSwitch>
          </Form>
        </>
      ) : formType === 'forgot-password' ? (
        <>
          <Title data-cy='login-title' level={3}>
            <FormattedMessage {...messages.forgottenPasswordTitle} />
          </Title>
          <Paragraph>
            <FormattedMessage {...messages.forgottenPasswordLabel} />
          </Paragraph>
          <Space direction='vertical' size={16} style={{ width: '100%' }}>
            <Form
              onSubmit={async () => {
                if (hasForgotPasswordFormValidationFailed() || loading) {
                  return
                }

                setLoading(true)
                await discoverByEmail(forgotPasswordEmail.value.trim())
                resetPassword({
                  variables: { input: { email: forgotPasswordEmail.value.trim() } },
                })
              }}
            >
              <Form.Item
                label={
                  <LabelWrapper>
                    <FormattedMessage {...sharedMessages.emailLabel} />
                  </LabelWrapper>
                }
                errorMessage={
                  forgotPasswordEmail.error && (
                    <FormattedMessage {...sharedMessages.invalidEmailError} />
                  )
                }
              >
                <Input
                  size='large'
                  type='email'
                  autoComplete='email'
                  value={forgotPasswordEmail.value}
                  onChange={({ target: { value } }) =>
                    setForgotPasswordEmail({ value, error: false })
                  }
                />
              </Form.Item>
              <Button type='submit' isFullWidth>
                <FormattedMessage {...messages.forgottenPasswordSend} />
              </Button>
            </Form>
            <Button
              variant='text'
              isFullWidth
              onClick={() => {
                setEmail(forgotPasswordEmail)
                setFormType('login')
              }}
            >
              <FormattedMessage {...messages.backToLogin} />
            </Button>
          </Space>
        </>
      ) : formType === '2fa' ? (
        <>
          <Title data-cy='login-title' level={3}>
            <FormattedMessage {...messages.twoFactorAuthenticationTitle} />
          </Title>
          <Form
            onSubmit={() => {
              if (hasLoginFormValidationFailed() || has2FAFormValidationFailed() || loading) {
                return
              }

              login()
            }}
          >
            <Form.Item>
              <Center>
                <FormattedMessage
                  {...messages.twoFactorAuthenticationDescription}
                  values={{ mail: msg => <a href={`mailto:${msg}`}>{msg}</a> }}
                />
              </Center>
            </Form.Item>
            <Form.Item
              label={<FormattedMessage {...messages.codeLabel} />}
              errorMessage={code.error && <FormattedMessage {...messages.codeError} />}
            >
              <Input
                size='large'
                type='text'
                inputMode='numeric'
                autoComplete='off'
                value={code.value}
                onChange={({ target: { value } }) => {
                  setCode({ value, error: false })
                }}
              />
            </Form.Item>
            <Button type='submit' isFullWidth>
              <FormattedMessage {...messages.login} />
            </Button>
          </Form>
        </>
      ) : (
        <>
          <ImageMailSent src={mailSent} alt='Mail sent' />
          <Title data-cy='login-title' level={3}>
            <FormattedMessage {...messages.forgottenPasswordTitleDone} />
          </Title>
          <Center>
            <FormattedMessage
              {...messages.forgottenPasswordDone}
              values={{
                contactUs: contactUs => <a href={`mailto:${supportEmail}`}>{contactUs}</a>,
              }}
            />
          </Center>
        </>
      )}
    </PageTemplateUnlogged>
  )
}

const Title = styled(AntTitle)`
  text-align: center;
  margin-bottom: 20px !important;
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`

export const SSOPageSwitch = styled.div`
  text-align: center;
  margin-top: 16px;
  font-weight: 600;
`

const ImageMailSent = styled.img`
  margin-bottom: 1rem;
`

const Center = styled.div`
  text-align: center;
`

const Paragraph = styled.p`
  text-align: center;
`

export const RegisterBox = styled.div`
  margin-top: 2rem;
  color: #688699;

  ${mq.smUp} {
    display: none;
  }
`

export default Login
