import { UntitledIcon } from '@faceup/icons'
import { ulAlertCircle } from '@faceup/icons/ulAlertCircle'
import { ulXClose } from '@faceup/icons/ulXClose'
import { Button } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import styles from './AlertBar.module.css'

type Color = 'orange' | 'red'

const classColors: Record<Color, string> = {
  orange: styles.orange,
  red: styles.red,
}

type AlertBarProps = {
  isVisible?: boolean
  onClose?: () => void
  color: Color
  children: ReactNode
  button?: {
    text: ReactNode
    onClick: () => void
  }
}

export const AlertBar = ({ children, color, isVisible = true, onClose, button }: AlertBarProps) => {
  if (!isVisible) {
    return null
  }
  return (
    <div className={`${styles.root} ${classColors[color]}`}>
      <div className={styles.content}>
        <UntitledIcon icon={ulAlertCircle} size={16} />
        <span>{children}</span>
        {button && (
          <Button onClick={button.onClick} size='small' type='default' ghost>
            {button.text}
          </Button>
        )}
      </div>
      {onClose && (
        <div className={styles.close}>
          <Button onClick={onClose} type='text'>
            <UntitledIcon icon={ulXClose} color='#fff' size={24} />
          </Button>
        </div>
      )}
    </div>
  )
}
