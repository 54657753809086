import styled from '@emotion/styled'
import { Card, type CardProps, Layout } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import PublicHeader, { type PublicHeaderProps } from '../Components/PublicHeader'
import { mq } from '../constants'
import Footer from './Login/Footer'

const { Content: AntContent } = Layout

type PageTemplateUnloggedProps = {
  publicHeaderProps?: PublicHeaderProps
  children?: ReactNode
  contentAboveCard?: ReactNode
  contentUnderCard?: ReactNode
  isFooterShown?: boolean
  cardProps?: Omit<CardProps, 'children'>
}

const PageTemplateUnlogged = ({
  children,
  contentAboveCard,
  contentUnderCard,
  publicHeaderProps,
  isFooterShown = false,
  cardProps,
}: PageTemplateUnloggedProps) => (
  <LayoutFullHeight>
    <PublicHeader {...publicHeaderProps} />
    <Content>
      {contentAboveCard}
      <ContentCard {...cardProps}>{children}</ContentCard>
      {contentUnderCard}
    </Content>
    {isFooterShown && <Footer />}
  </LayoutFullHeight>
)

const LayoutFullHeight = styled(Layout)`
  min-height: 100%;
  background-color: #FBFCFF;  
`
const Content = styled(AntContent)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 2em;
  background-color: #FBFCFF;
`
const ContentCard = styled(Card)`
  max-width: 35rem;
  width: 100%;

  .ant-card-body {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 3.5rem 24px;

    ${mq.smUp} {
      padding: 3.5rem 6rem;
    }
  }
`

export default PageTemplateUnlogged
