import { Table as AntTable, type TableProps as AntTableProps } from 'antd'
import type { AnyObject } from 'antd/es/_util/type'

export type TableProps<D> = {
  shadow?: boolean
} & Omit<AntTableProps<D>, 'bordered'>

export const Table = <D extends AnyObject>({ shadow, ...props }: TableProps<D>) => {
  return <AntTable {...props} className={`${shadow ? 'ant-table-shadow' : ''}`} />
}
