import { gql } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { usAlertCircle } from '@faceup/icons/usAlertCircle'
import { Space } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import type { PaymentNoticeMessage_company } from '../../__generated__/globalTypes'
import useSubscriptionDetail, {
  type SubscriptionDetail,
  SubscriptionDetailFragments,
} from '../../hooks/useSubscriptionDetail'

const messages = defineMessages({
  trialEndsIn: 'Administration.trial.endsIn',
  trialEnded: 'Administration.trial.ended',
  subscriptionEnded: 'Administration.paymentNotice.subscriptionEnded',
  subscriptionValidTo: 'Administration.paymentNotice.subscriptionValidTo',
})

export const PaymentNoticeMessageFragments = {
  PaymentNoticeMessage_company: gql`
    fragment PaymentNoticeMessage_company on Company {
      id

      ...SubscriptionDetail_company
    }

    ${SubscriptionDetailFragments.SubscriptionDetail_company}
  `,
}

type PaymentNoticeMessageProps = {
  institution: PaymentNoticeMessage_company
  isWrapped?: boolean
  isSingleColor?: boolean
}

export const PaymentNoticeMessage = ({
  institution,
  isWrapped = true,
  isSingleColor = false,
}: PaymentNoticeMessageProps) => {
  const getSubscriptionDetail = useSubscriptionDetail()
  const subscription = getSubscriptionDetail(institution)
  const { warningColor } = subscription

  if (!isWrapped) {
    return <Message subscription={subscription} isSingleColor={isSingleColor} />
  }

  return (
    <Space data-cy='PaymentNoticeMessage'>
      <UntitledIcon icon={usAlertCircle} color={warningColor} />
      <Message subscription={subscription} isSingleColor={isSingleColor} />
    </Space>
  )
}

type MessageProps = {
  subscription: SubscriptionDetail
  isSingleColor: boolean
}

const Message = ({
  subscription: { state, warningColor, humanizedSubscriptionEnd, humanizedSubscriptionEndDate },
  isSingleColor,
}: MessageProps) => {
  const messageMap: Record<SubscriptionDetail['state'], () => ReactNode> = {
    trialExpired: () => <FormattedMessage {...messages.trialEnded} />,
    cancelled: () => <FormattedMessage {...messages.subscriptionEnded} />,
    inTrial: () => (
      <span>
        <FormattedMessage {...messages.trialEndsIn} />{' '}
        <span style={{ color: isSingleColor ? undefined : warningColor }}>
          {humanizedSubscriptionEnd}
        </span>
      </span>
    ),
    nonRenewing: () => (
      <span>
        <FormattedMessage {...messages.subscriptionValidTo} />{' '}
        <span>{humanizedSubscriptionEndDate}</span>
      </span>
    ),
    active: () => null,
    future: () => null,
  }

  return messageMap[state]()
}
