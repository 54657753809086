import { gql } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulRocket02 } from '@faceup/icons/ulRocket02'
import { Link } from '@faceup/router'
import { Button } from '@faceup/ui'
import { Space, Typography } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import type { PaymentNotice_company } from '../../__generated__/globalTypes'
import useSubscriptionDetail, {
  SubscriptionDetailFragments,
} from '../../hooks/useSubscriptionDetail'
import useAnalytics from '../../utils/analytics'
import { PaymentNoticeAlertBar, PaymentNoticeAlertBarFragments } from './PaymentNoticeAlertBar'
import { PaymentNoticeMessage, PaymentNoticeMessageFragments } from './PaymentNoticeMessage'

const { Text } = Typography

const messages = defineMessages({
  buy: 'Administration.trial.buy',
  renew: 'Administration.paymentNotice.renew',
})

export const PaymentNoticeFragments = {
  PaymentNotice_company: gql`
    fragment PaymentNotice_company on Company {
      id

      billing {
        id
        subscriptionStatus
        subscriptionEnd
      }

      ...SubscriptionDetail_company
      ...PaymentNoticeMessage_company
      ...PaymentNoticeAlertBar_company
    }

    ${SubscriptionDetailFragments.SubscriptionDetail_company}
    ${PaymentNoticeMessageFragments.PaymentNoticeMessage_company}
    ${PaymentNoticeAlertBarFragments.PaymentNoticeAlertBar_company}
  `,
}

type Variant = 'inline' | 'alertBar'

type PaymentNoticeProps = {
  mother: PaymentNotice_company
  variant?: Variant
}

const PaymentNotice = ({ mother, variant = 'inline' }: PaymentNoticeProps) => {
  const getSubscriptionDetail = useSubscriptionDetail()
  const { state } = getSubscriptionDetail(mother)
  const { trackBuyActionClicked } = useAnalytics()

  const realInstitution = mother?.realInstitution
  const showPaymentInfo =
    ['trialExpired', 'cancelled', 'inTrial', 'nonRenewing'].includes(state) && realInstitution

  if (!showPaymentInfo) {
    return null
  }

  const variants: Record<Variant, () => ReactNode> = {
    alertBar: () => <PaymentNoticeAlertBar company={mother} />,
    inline: () => (
      <Space size='large'>
        <Text strong>
          <PaymentNoticeMessage institution={mother} />
        </Text>
        <Link to={routes => routes.settingsInvoicing()} onClick={() => trackBuyActionClicked()}>
          <Button
            iconBefore={
              <UntitledIcon icon={ulRocket02} fontSize='1rem' style={{ marginRight: '0.5rem' }} />
            }
          >
            {state === 'nonRenewing' ? (
              <FormattedMessage {...messages.renew} />
            ) : (
              <FormattedMessage {...messages.buy} />
            )}
          </Button>
        </Link>
      </Space>
    ),
  }
  return variants[variant]()
}

export default PaymentNotice
