import { transitionDuration } from '@faceup/ui'
import { Link, RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap'
import { IconClearFormatting } from '@tabler/icons-react'
import CharacterCount from '@tiptap/extension-character-count'
import Highlight from '@tiptap/extension-highlight'
import SubScript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useEffect } from 'react'

export type TextEditorProps = {
  content: string
  onChangeContent: (content: string) => void
  maxLength?: number
}

/**
 * Wysiwyg editor
 *
 * Why aren't used untitled icons?
 * It's now buggy when clicking on the icon.
 * Random number of clicks was needed to apply wanted manipulation with content.
 *
 */
const TextEditor = ({ content, onChangeContent, maxLength }: TextEditorProps) => {
  const editor = useEditor({
    extensions: [
      CharacterCount.configure({
        mode: 'nodeSize',
        limit: maxLength,
      }),
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content,
    onUpdate: ({ editor }) => {
      onChangeContent?.(editor.getHTML())
    },
  })

  useEffect(() => {
    if (content !== editor?.getHTML()) {
      editor?.commands?.setContent(content)
    }
  }, [content, editor])

  return (
    <RichTextEditor
      editor={editor}
      styles={styles => ({
        toolbar: {
          backgroundColor: '#F5F7F8',
          borderBottom: 0,
          marginInline: 3,
          marginTop: 3,
        },
        control: {
          borderRadius: '2px!important',
          border: 0,
          backgroundColor: 'unset',
          transition: `background-color ${transitionDuration}`,
          '&[data-interactive="true"]:hover': {
            backgroundColor: `${styles.colors.primary[2]}!important`,
          },
          '&[data-active="true"]': {
            backgroundColor: `${styles.colors.primary[3]}!important`,
          },
          '& svg': {
            width: '1.25rem!important',
            height: '1.25rem!important',
          },
        },
        content: {
          '.ProseMirror': {
            minHeight: 300,
          },
          'h1, h2, h3, h4, h5, h6': {
            fontFamily: 'Inter',
            fontWeight: 600,
          },
          wordBreak: 'break-word',
        },
        linkEditorSave: {
          height: 36,
        },
      })}
    >
      <RichTextEditor.Toolbar sticky>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.Highlight />
          <RichTextEditor.Code />
          <ClearAllFormatting />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Blockquote />
          <RichTextEditor.Hr />
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
          <RichTextEditor.Subscript />
          <RichTextEditor.Superscript />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>

      <RichTextEditor.Content />
    </RichTextEditor>
  )
}

/**
 * Custom button for clearing all formatting is needed,
 * since the default one does not act as expected
 * (i.e. it does only reset fonts but not headings etc.)
 *
 * This does remove everything.
 */
const ClearAllFormatting = () => {
  const { editor } = useRichTextEditorContext()
  return (
    <RichTextEditor.Control
      onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()}
      aria-label='Clear all formatting'
      title='Clear all formatting'
    >
      <IconClearFormatting stroke={1.5} size='1rem' />
    </RichTextEditor.Control>
  )
}

export default TextEditor
