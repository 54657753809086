import type { UntitledIconData } from '@faceup/icons'

export const ucLifeRingButton: UntitledIconData = {
  prefix: 'custom',
  name: 'ucLifeRingButton',
  viewBox: '0 0 20 20',
  paths: [
    {
      shape:
        'M7.57496 7.49996C7.77088 6.94302 8.15759 6.47338 8.66659 6.17424C9.17559 5.87509 9.77404 5.76574 10.3559 5.86555C10.9378 5.96536 11.4656 6.2679 11.8459 6.71957C12.2261 7.17124 12.4342 7.74289 12.4333 8.33329C12.4333 9.99996 9.93329 10.8333 9.93329 10.8333M9.99996 14.1666H10.0083M18.3333 9.99996C18.3333 14.6023 14.6023 18.3333 9.99996 18.3333C5.39759 18.3333 1.66663 14.6023 1.66663 9.99996C1.66663 5.39759 5.39759 1.66663 9.99996 1.66663C14.6023 1.66663 18.3333 5.39759 18.3333 9.99996Z',
      stroke: 'currentColor',
      strokeWidth: '1.25',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
}
