import './utils/analytics'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from '@faceup/router'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { name, version } from '../package.json'
import App from './App'

if (import.meta.env.VITE_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: 'https://60657b40d58442c5825f096a3d884190@o441414.ingest.sentry.io/5428579',
    integrations: [
      // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#usage-with-react-router-64-data-api
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        mask: ['.sensitive'],
      }),
    ],
    tracesSampleRate: 0.33,
    release: `${name.replace('@faceup/', '')}@${version}`, // after changing release name, it is required to change it in sourcemaps upload as well
    environment: import.meta.env.VITE_ENVIRONMENT ?? '',
    replaysSessionSampleRate: 0.33,
    replaysOnErrorSampleRate: 1.0,
    normalizeDepth: 6,
  })
}

const container = document.getElementById('app')
if (container) {
  const root = createRoot(container)
  root.render(<App />)
}
