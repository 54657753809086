import { Layout } from '@faceup/ui-base'
import type { PropsWithChildren, ReactNode } from 'react'
import { useThemeColors } from '../hooks'
import styles from './assets/AdminLayout.module.css'

type AdminLayoutProps = PropsWithChildren<{
  menu: ReactNode
  header?: ReactNode
}>

export const AdminLayout = ({ children, menu, header }: AdminLayoutProps) => {
  const { getColorFromTheme } = useThemeColors()
  const panelBackground = getColorFromTheme('dark.100')

  return (
    <Layout className={styles.layout}>
      <Layout.Header className={styles.header}>{header}</Layout.Header>
      <Layout>
        <Layout.Sider
          collapsed
          collapsedWidth='64px'
          style={{
            backgroundColor: panelBackground,
          }}
        >
          {menu}
        </Layout.Sider>
        <Layout>
          <Layout.Content className={styles.content}>{children}</Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  )
}
