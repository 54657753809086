import { gql, useMutation, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { UserContext } from '@faceup/member'
import { Button, Card } from '@faceup/ui'
import { Form, Input, Modal, Slider, Space, Typography, notification } from '@faceup/ui-base'
import {
  CHARGEBEE_DEFAULT_PLAN_QUANTITY,
  DEFAULT_CURRENCY,
  Institution,
  InvoicedTo,
  Plan,
} from '@faceup/utils'
import { Grid, Group, Stack, Text } from '@mantine/core'
import moment from 'moment-timezone'
import { useContext, useEffect, useMemo, useState } from 'react'
import { FormattedNumber } from 'react-intl'
import ContactSalesModal from '../../../Components/ContactSalesModal'
import InvoicingPlan, { type InvoicingPlanButtonProps } from '../../../Components/InvoicingPlan'
import { sharedMessages } from '../../../Shared/translations'
import {
  FormattedMessage,
  type TypedMessageDescriptor,
  defineMessages,
  useIntl,
} from '../../../TypedIntl'
import {
  type BeforePurchaseQuery,
  type BeforePurchaseQueryVariables,
  type ChangeTrialSubscriptionMutation,
  type ChangeTrialSubscriptionMutationVariables,
  ChargebeeSubscriptionStatus,
  type CompanyInvoicingPriceListInstitutionFragment,
  ContactIssue,
  type CreateSubscriptionMutation,
  type CreateSubscriptionMutationVariables,
  Currency,
  BillingFrequency as GlobalBillingFrequency,
} from '../../../__generated__/globalTypes'
import { useMotherId } from '../../../hooks/useMotherId'
import useAnalytics from '../../../utils/analytics'
import { getContactSalesIssue, mapPriceDivider } from '../CompanyInvoicing'
import BillingPeriodSwitcher, { type BillingFrequency } from '../Components/BillingPeriodSwitcher'
import contactSalesIcon from '../assets/contact-sales-icon.svg'
import { CompanyInvoicingInstitutionAndSchoolFragment } from './CompanyInvoicingInstitutionAndSchoolFragment'

type CompanyInvoicingPriceListInstitutionFragment_versions_prices = NonNullable<
  NonNullable<
    NonNullable<CompanyInvoicingPriceListInstitutionFragment['versions']>[number]
  >['prices']
>[number]

const globalMessages = defineMessages({
  howManyEmployees: 'Administration.companyInvoicing.howManyEmployees',
  lowerThan: 'Administration.companyInvoicing.employeeCount.lowerThan',
  higherThan: 'Administration.companyInvoicing.employeeCount.higherThan',
  individualPrice: 'Administration.companyInvoicing.individualPrice',
  freePrice: 'Administration.companyInvoicing.freePrice',
  buy: 'Administration.companyInvoicing.button.buy',
  vatInfo: 'Administration.companyInvoicing.vatInfo',
  switchToPlan: 'Administration.companyInvoicing.button.switchToPlan',
  contactSales: 'Administration.companyInvoicing.button.contactSales',
  billingPlanTitle: 'Administration.companyInvoicing.billingPlan.title',
  contactSalesTitle: 'Administration.trialEnded.contactSales',

  coupon: 'Administration.companyInvoicing.coupon',
  couponLabel: 'Administration.companyInvoicing.coupon.label',
  couponError: 'Administration.companyInvoicing.coupon.error',
  couponDescription: 'Administration.companyInvoicing.coupon.description',
})

const schoolMessages = defineMessages<Extract<keyof typeof globalMessages, 'howManyEmployees'>>({
  howManyEmployees: 'Administration.companyInvoicing.howManyStudents',
})

type VariantMessages = 'lineText' | 'description' | 'benefits'

const variantSchoolFreeCzechMessages = defineMessages<VariantMessages>({
  lineText: 'Administration.companyInvoicing.school.czech.free.lineText',
  description: 'Administration.companyInvoicing.school.czech.free.description',
  benefits: 'Administration.companyInvoicing.school.czech.free.benefits',
})

const variantSchoolStandardCzechMessages = defineMessages<VariantMessages>({
  lineText: 'Administration.companyInvoicing.school.czech.standard.lineText',
  description: 'Administration.companyInvoicing.school.czech.standard.description',
  benefits: 'Administration.companyInvoicing.school.czech.standard.benefits',
})

const variantSchoolStandardMessages = defineMessages<Exclude<VariantMessages, 'description'>>({
  lineText: 'Administration.companyInvoicing.school.standard.lineText',
  benefits: 'Administration.companyInvoicing.school.standard.benefits',
})

const variantBasicMessages = defineMessages<VariantMessages>({
  lineText: 'Administration.companyInvoicing.basic.lineText',
  description: 'Administration.companyInvoicing.basic.description',
  benefits: 'Administration.companyInvoicing.basic.benefits',
})

const variantStandardMessages = defineMessages<VariantMessages>({
  lineText: 'Administration.companyInvoicing.standard.lineText',
  description: 'Administration.companyInvoicing.standard.description',
  benefits: 'Administration.companyInvoicing.standard.benefits',
})

const variantPremiumMessages = defineMessages<VariantMessages>({
  lineText: 'Administration.companyInvoicing.premium.lineText',
  description: 'Administration.companyInvoicing.premium.description',
  benefits: 'Administration.companyInvoicing.premium.benefits',
})

const query = {
  BeforePurchaseQuery: gql`
    query BeforePurchaseQuery($motherId: CompanyGlobalId!) {
      priceList {
        monthly {
          ...CompanyInvoicingInstitutionAndSchoolFragment
        }
        yearly {
          ...CompanyInvoicingInstitutionAndSchoolFragment
        }
        quarterly {
          ...CompanyInvoicingInstitutionAndSchoolFragment
        }
      }
      memberViewer {
        id
        mother(motherId: $motherId) {
          id
          type
          identificationNumber
          billing {
            id
            subscriptionEnd
            subscriptionStatus
            currency
            employees
            plan
            frequency
            price
            invoicedTo
          }
        }
      }
    }

    ${CompanyInvoicingInstitutionAndSchoolFragment}
  `,
}

const mutations = {
  CreateSubscriptionMutation: gql`
    mutation CreateSubscriptionMutation($input: CreateSubscriptionInput!) {
      createSubscription(input: $input) {
        url
      }
    }
  `,
  ChangeTrialSubscriptionMutation: gql`
    mutation ChangeTrialSubscriptionMutation($input: ChangeTrialSubscriptionInput!) {
      changeTrialSubscription(input: $input) {
        company {
          id
          billing {
            id
            subscriptionEnd
            subscriptionStatus
            currency
            employees
            plan
            frequency
            price
          }
        }
      }
    }
  `,
}

const employeesSliderLayoutDefinition: Record<
  Institution,
  Record<'span' | 'offset' | 'defaultIndex', number>
> = {
  [Institution.Company]: {
    span: 10,
    offset: 1,
    defaultIndex: 1,
  },
  [Institution.School]: {
    span: 6,
    offset: 3,
    defaultIndex: 0,
  },
}

type BeforePurchaseProps = { institutionId?: string }

export const BeforePurchase = ({ institutionId }: BeforePurchaseProps) => {
  const { viewer: partnerViewer } = useContext(UserContext)
  const [billingFrequency, setBillingFrequency] = useState<BillingFrequency>('yearly')
  const [userLimit, setUserLimit] = useState(0)
  const [isCouponModalVisible, setIsCouponModalVisible] = useState(false)
  const [clickedButton, setClickedButton] = useState<Plan>()
  const [openedContactSalesModalIssue, setOpenedContactSalesModalIssue] = useState<ContactIssue>()
  const [coupon, setCoupon] = useState({
    value: '',
    error: false,
  })
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()
  const { trackCheckoutStarted } = useAnalytics()

  const motherId = institutionId ?? getMotherId()
  const isPartner = partnerViewer?.isPartner === true

  const { data, loading } = useQuery<BeforePurchaseQuery, BeforePurchaseQueryVariables>(
    query.BeforePurchaseQuery,
    {
      variables: {
        motherId,
      },
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
    }
  )

  const viewer = data?.memberViewer
  const mother = viewer?.mother
  const billing = mother?.billing
  const employeesSliderLayout = mother ? employeesSliderLayoutDefinition[mother?.type] : null
  const currency = billing?.currency ?? DEFAULT_CURRENCY
  const billingMap: Record<BillingFrequency, GlobalBillingFrequency> = {
    monthly: GlobalBillingFrequency.Monthly,
    yearly: GlobalBillingFrequency.Yearly,
    quarterly: GlobalBillingFrequency.Quarterly,
  }

  const [createSubscription, { loading: creatingSubscription }] = useMutation<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >(mutations.CreateSubscriptionMutation, {
    onError: error => {
      if (error.graphQLErrors[0]?.message === 'Coupon is invalid') {
        setCoupon({ ...coupon, error: true })
        return
      }
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })
  const [changeTrialSubscription] = useMutation<
    ChangeTrialSubscriptionMutation,
    ChangeTrialSubscriptionMutationVariables
  >(mutations.ChangeTrialSubscriptionMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  useEffect(() => {
    if (billing?.frequency) {
      setBillingFrequency(billing.frequency.toLowerCase() as BillingFrequency)
    }
  }, [billing?.frequency])

  const priceListVariant = useMemo(
    () =>
      data?.priceList?.[billingFrequency]?.[
        mother?.type === Institution.School ? 'school' : 'company'
      ]?.filter(
        // We want to include only variants with specified currency
        priceVariant =>
          priceVariant?.versions?.some(version =>
            version?.prices?.find(price => price?.currency === currency)
          )
      ) ?? null,
    [data?.priceList, currency, mother?.type, billingFrequency]
  )
  const messages = {
    ...globalMessages,
    ...(mother?.type === Institution.School ? schoolMessages : {}),
  }

  const handleClickBuy = async (plan: Plan, employees: number, showCouponModal: boolean) => {
    if (showCouponModal) {
      setIsCouponModalVisible(true)
      return
    }

    if (plan && employees && !clickedButton) {
      setClickedButton(plan)
      trackCheckoutStarted(plan)
      const { data } = await createSubscription({
        variables: {
          input: {
            motherId,
            employees,
            plan,
            billingFrequency: billingMap[billingFrequency],
            coupon: coupon.value.trim() ? coupon.value.trim() : null,
          },
        },
      })

      if (data?.createSubscription?.url) {
        window.location.href = data.createSubscription.url
      } else {
        setClickedButton(undefined)
      }
    }
  }

  const handleClickSwitch = async (plan: Plan, employees: number) => {
    if (plan && !clickedButton) {
      setClickedButton(plan)
      await changeTrialSubscription({
        variables: {
          input: {
            plan,
            motherId,
            billingFrequency: billingMap[billingFrequency],
            employees,
          },
        },
      })
      setClickedButton(undefined)
    }
  }

  const employeesCount = (priceListVariant || [])
    .map(item => item?.members)
    .filter((item): item is number => Boolean(item))

  const marks = employeesCount.reduce(
    (acc, _emp, i) => {
      const employees = {
        minEmployeesCount: employeesCount?.[i - 1] ?? 0,
        maxEmployeesCount: employeesCount?.[i] ?? 0,
      }

      const translation = i === employeesCount.length - 1 ? messages.higherThan : messages.lowerThan

      return { ...acc, [i]: formatMessage(translation, employees) }
    },
    {} as Record<number, string>
  )

  const priceItemFree = priceListVariant?.[userLimit]?.versions
    ?.find(item => item?.version === Plan.Free)
    ?.prices?.find(price => price?.currency === currency)
  const priceItemBasic = priceListVariant?.[userLimit]?.versions
    ?.find(item => item?.version === Plan.Basic)
    ?.prices?.find(price => price?.currency === currency)
  const priceItemStandard = priceListVariant?.[userLimit]?.versions
    ?.find(item => item?.version === Plan.Standard)
    ?.prices?.find(price => price?.currency === currency)
  const priceItemPremium = priceListVariant?.[userLimit]?.versions
    ?.find(item => item?.version === Plan.Premium)
    ?.prices?.find(price => price?.currency === currency)
  const individualPrice = userLimit === (priceListVariant?.length ?? 0) - 1
  const isTrial = billing?.subscriptionStatus === ChargebeeSubscriptionStatus.InTrial
  const showFreePlan = billing?.currency === Currency.CZK && mother?.identificationNumber

  const getTranslationsForPlan = (
    messages: Partial<Record<VariantMessages, TypedMessageDescriptor>>
  ) => ({
    description: messages?.description ? formatMessage(messages.description) : undefined,
    features: messages?.benefits ? formatMessage(messages.benefits).split('\n') : [],
    lineText: messages.lineText ? formatMessage(messages.lineText) : undefined,
  })

  const getButton = (
    plan: Exclude<Plan, Plan.Unregistered>
  ): InvoicingPlanButtonProps | undefined => {
    const selectedMembersCount = priceListVariant?.[userLimit]?.members

    const button: Record<'buy' | 'contactSales' | 'switch', InvoicingPlanButtonProps> = {
      buy: {
        children: <FormattedMessage {...messages.buy} />,
        onClick: () =>
          selectedMembersCount &&
          handleClickBuy(
            plan,
            selectedMembersCount,
            isPartner && billing?.invoicedTo === InvoicedTo.Partner
          ),
        loading: clickedButton === plan,
        disabled: Boolean(clickedButton) && clickedButton !== plan,
      },
      switch: {
        children: <FormattedMessage {...messages.switchToPlan} />,
        onClick: () => selectedMembersCount && handleClickSwitch(plan, selectedMembersCount),
        variant: 'secondary',
        loading: clickedButton === plan,
        disabled: Boolean(clickedButton) && clickedButton !== plan,
      },
      contactSales: {
        children: <FormattedMessage {...messages.contactSales} />,
        onClick: () => setOpenedContactSalesModalIssue(getContactSalesIssue(plan)),
        variant: 'secondary',
      },
    }

    const isInCurrentTrial = isTrial && billing?.plan === plan
    const subscriptionEnd = billing?.subscriptionEnd

    const today = moment()
    const isSubscriptionEnded =
      today.isAfter(subscriptionEnd) ||
      billing?.subscriptionStatus === ChargebeeSubscriptionStatus.Cancelled

    const allowBuyStandardPlanAsSchoolWithActiveFreePlan =
      plan === Plan.Standard &&
      mother?.type === Institution.School &&
      billing?.plan === Plan.Free &&
      billing?.subscriptionStatus === ChargebeeSubscriptionStatus.Active
    if (isInCurrentTrial || isSubscriptionEnded || allowBuyStandardPlanAsSchoolWithActiveFreePlan) {
      return individualPrice ? button.contactSales : button.buy
    }

    if (isTrial) {
      return button.switch
    }

    return undefined
  }

  const getPriceNode = (
    priceItem?: CompanyInvoicingPriceListInstitutionFragment_versions_prices | null
  ) => {
    if (individualPrice) {
      return (
        <IndividualPrice>
          <FormattedMessage {...messages.individualPrice} />
        </IndividualPrice>
      )
    }
    if (!priceItem?.billingPrice) {
      return <FormattedMessage {...messages.freePrice} />
    }
    const shownPrice = priceItem.billingPrice / 100 / mapPriceDivider[billingFrequency]
    return (
      priceItem?.billingPrice &&
      priceItem?.currency && (
        <FormattedNumber
          style='currency'
          value={shownPrice}
          currency={priceItem.currency}
          maximumFractionDigits={0}
        />
      )
    )
  }

  const getOriginalPriceNode = (
    priceItem?: CompanyInvoicingPriceListInstitutionFragment_versions_prices | null
  ) => {
    if (priceItem?.originalPrice && priceItem?.currency) {
      const shownPrice = priceItem.originalPrice / 100 / mapPriceDivider[billingFrequency]
      return (
        <FormattedNumber
          style='currency'
          value={shownPrice}
          currency={priceItem.currency}
          maximumFractionDigits={0}
        />
      )
    }
    return null
  }

  useEffect(() => {
    const employees = billing?.employees ?? 0
    const variantIndex =
      (priceListVariant?.findIndex(variant => (variant?.members ?? 0) >= employees) ||
        employeesSliderLayout?.defaultIndex) ??
      0
    setUserLimit(variantIndex)
  }, [billing?.employees, employeesSliderLayout?.defaultIndex, priceListVariant])

  if (!mother || !employeesSliderLayout) {
    return null
  }

  return (
    <>
      <Modal
        title={formatMessage(messages.coupon)}
        centered
        open={Boolean(isCouponModalVisible)}
        okButtonProps={{
          loading: creatingSubscription,
          disabled: coupon.error,
        }}
        maskClosable={false}
        onCancel={() => setIsCouponModalVisible(false)}
        onOk={() =>
          coupon.error
            ? null
            : handleClickBuy(
                billing?.plan ?? Plan.Standard,
                priceListVariant?.[userLimit]?.members ?? CHARGEBEE_DEFAULT_PLAN_QUANTITY,
                false
              )
        }
        okText={formatMessage(messages.buy)}
      >
        <Form layout='vertical'>
          <FormattedMessage {...messages.couponDescription} tagName={Typography.Paragraph} />
          <Form.Item
            label={<FormattedMessage {...messages.couponLabel} />}
            {...(coupon.error && {
              validateStatus: 'error',
              help: <FormattedMessage {...messages.couponError} />,
            })}
          >
            <Input
              value={coupon.value}
              onChange={e => setCoupon({ value: e.target.value, error: false })}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Card
        title={<FormattedMessage {...messages.billingPlanTitle} />}
        extra={
          <Button
            onClick={() => {
              setOpenedContactSalesModalIssue(ContactIssue.ContactViaBilling)
            }}
          >
            <img src={contactSalesIcon} alt='' />
            <span style={{ paddingLeft: 10 }}>
              <FormattedMessage {...messages.contactSalesTitle} />
            </span>
          </Button>
        }
        loading={loading}
      >
        <Grid justify='center' align='center'>
          <Grid.Col xl={10}>
            <Stack>
              <Grid style={{ marginBottom: 24 }}>
                <Grid.Col span={employeesSliderLayout.span} offset={employeesSliderLayout.offset}>
                  <StyledEmployees>
                    <EmployeesCardInnerWrapper>
                      <EmployeesWrapper>
                        <EmployeesText>
                          <FormattedMessage {...messages.howManyEmployees} />
                        </EmployeesText>
                      </EmployeesWrapper>
                      <Slider
                        step={1}
                        min={0}
                        max={Object.keys(marks).length - 1}
                        dots={false}
                        tipFormatter={value => value && marks[value]}
                        defaultValue={employeesSliderLayout.defaultIndex}
                        value={userLimit}
                        onChange={value => setUserLimit(value)}
                        tooltipVisible={false}
                        disabled={
                          Boolean(clickedButton) ||
                          (billing?.subscriptionStatus !== ChargebeeSubscriptionStatus.InTrial &&
                            billing?.subscriptionStatus !== ChargebeeSubscriptionStatus.Cancelled &&
                            billing?.plan !== Plan.Free)
                        }
                      />
                    </EmployeesCardInnerWrapper>
                    <Group position='apart'>
                      {Object.keys(marks).map((_value, value) => (
                        // biome-ignore lint/suspicious/noArrayIndexKey:
                        <SliderDescription key={value}>
                          {userLimit === value ? <Strong>{marks[value]}</Strong> : marks[value]}
                        </SliderDescription>
                      ))}
                    </Group>
                  </StyledEmployees>
                </Grid.Col>
              </Grid>
              {mother.type === Institution.Company && (
                <Space
                  align='center'
                  direction='vertical'
                  style={{ width: '100%', marginBottom: 24 }}
                >
                  <BillingPeriodSwitcher
                    frequency={billingFrequency}
                    onChange={variant => setBillingFrequency(variant)}
                    initialFrequency={billing?.frequency.toLowerCase() as BillingFrequency}
                  />
                </Space>
              )}
              <Grid gutter={34}>
                {mother.type === Institution.School ? (
                  showFreePlan ? (
                    <>
                      <Grid.Col span={4} offset={2}>
                        <InvoicingPlan
                          {...getTranslationsForPlan(variantSchoolFreeCzechMessages)}
                          name={Plan.Free}
                          billingFrequency={billingFrequency}
                          price={getPriceNode(priceItemFree)}
                          originalPrice={getOriginalPriceNode(priceItemFree)}
                          variant={billing?.plan === Plan.Free ? 'currentPlan' : undefined}
                          button={getButton(Plan.Free)}
                          isPricingInfoHidden
                        />
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <InvoicingPlan
                          {...getTranslationsForPlan(variantSchoolStandardCzechMessages)}
                          name={Plan.Standard}
                          billingFrequency={billingFrequency}
                          price={getPriceNode(priceItemStandard)}
                          originalPrice={getOriginalPriceNode(priceItemStandard)}
                          variant={billing?.plan === Plan.Standard ? 'currentPlan' : undefined}
                          button={getButton(Plan.Standard)}
                          isPricingInfoHidden={individualPrice}
                          featureIcon='plus'
                        />
                      </Grid.Col>
                    </>
                  ) : (
                    <Grid.Col span={4} offset={4}>
                      <InvoicingPlan
                        {...getTranslationsForPlan(variantSchoolStandardMessages)}
                        name={Plan.Standard}
                        billingFrequency={billingFrequency}
                        price={getPriceNode(priceItemStandard)}
                        originalPrice={getOriginalPriceNode(priceItemStandard)}
                        variant={billing?.plan === Plan.Standard ? 'currentPlan' : undefined}
                        button={getButton(Plan.Standard)}
                        isPricingInfoHidden={individualPrice}
                      />
                    </Grid.Col>
                  )
                ) : (
                  <>
                    <Grid.Col span={4}>
                      <InvoicingPlan
                        {...getTranslationsForPlan(variantBasicMessages)}
                        name={Plan.Basic}
                        billingFrequency={billingFrequency}
                        price={getPriceNode(priceItemBasic)}
                        originalPrice={getOriginalPriceNode(priceItemBasic)}
                        variant={billing?.plan === Plan.Basic ? 'currentPlan' : undefined}
                        button={getButton(Plan.Basic)}
                        isPricingInfoHidden={individualPrice}
                      />
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <InvoicingPlan
                        {...getTranslationsForPlan(variantStandardMessages)}
                        name={Plan.Standard}
                        billingFrequency={billingFrequency}
                        price={getPriceNode(priceItemStandard)}
                        originalPrice={getOriginalPriceNode(priceItemStandard)}
                        variant={billing?.plan === Plan.Standard ? 'currentPlan' : undefined}
                        button={getButton(Plan.Standard)}
                        isPricingInfoHidden={individualPrice}
                        featureIcon='plus'
                      />
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <InvoicingPlan
                        {...getTranslationsForPlan(variantPremiumMessages)}
                        name={Plan.Premium}
                        billingFrequency={billingFrequency}
                        price={getPriceNode(priceItemPremium)}
                        originalPrice={getOriginalPriceNode(priceItemPremium)}
                        variant={billing?.plan === Plan.Premium ? 'currentPlan' : undefined}
                        button={getButton(Plan.Premium)}
                        isPricingInfoHidden={individualPrice}
                        featureIcon='plus'
                      />
                    </Grid.Col>
                  </>
                )}
              </Grid>
            </Stack>
            <Text sx={{ marginTop: 32 }} ta='center' c='dimmed' fz='xs'>
              <FormattedMessage {...globalMessages.vatInfo} />
            </Text>
          </Grid.Col>
        </Grid>
      </Card>
      {openedContactSalesModalIssue && (
        <ContactSalesModal
          contactIssue={openedContactSalesModalIssue}
          onClose={() => setOpenedContactSalesModalIssue(undefined)}
          opened
        />
      )}
    </>
  )
}

const StyledEmployees = styled.div`
  border: 1px solid #e6eaec;
  border-radius: 10px;
  padding: 14px;
`

const EmployeesWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-inline: 26px;
  margin-block-start: 14px;
  margin-block-end: 28px;
  justify-content: center;
`

const EmployeesText = styled(Typography.Title)`
  margin: 0;
  font-size: 20px;
  line-height: 24px;
`

const SliderDescription = styled(Typography.Text)`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #9babb5;
  text-align: center;
  display: block;
`

const Strong = styled.strong`
  color: #062d46;
`

const EmployeesCardInnerWrapper = styled.div`
  padding-inline: 24px;
`

const IndividualPrice = styled.div`
  font-size: 24px;
  font-family: Inter, sans-serif;
  font-weight: 600;
`
