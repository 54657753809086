import { TokenType } from '@faceup/utils'
import { ok } from 'neverthrow'
import { getCurrentEncryptionVersion } from '../end2end/login'
import { savePersonalKeys } from '../keys'
import { changePassword } from '../password'
import { createRecoveryKey, createSystemRecoveryKey } from '../recovery'
import { mapErr } from '../utils/general'

export type ProcessCreatePasswordPayload = {
  password: string
  name: string
  recoveryKey: string | undefined
  isE2EE: boolean
  systemPublicKey: string
}

export const processCreatePassword = async ({
  password,
  name,
  recoveryKey,
  isE2EE,
  systemPublicKey,
}: ProcessCreatePasswordPayload) => {
  const passwordPayload = await changePassword(password ?? '', 'generate')

  if (passwordPayload.isErr()) {
    return mapErr(passwordPayload, 'Could not change password')
  }

  const { privateKeyEncrypted, nonce, passwordKey, publicKey, salt, passwordPrehash } =
    passwordPayload.value
  const createdRecoveryKey = await createRecoveryKey(
    privateKeyEncrypted,
    nonce,
    passwordKey,
    recoveryKey ?? undefined
  )

  if (createdRecoveryKey.isErr()) {
    return mapErr(createdRecoveryKey, 'Could not create recovery key')
  }

  const systemRecoveryKey = await createSystemRecoveryKey(
    createdRecoveryKey.value.recoveryKeyPlain,
    systemPublicKey
  )

  if (systemRecoveryKey.isErr()) {
    return mapErr(systemRecoveryKey, 'Could not create system recovery key')
  }

  const savePayload = await savePersonalKeys({
    publicKey,
    privateKey: privateKeyEncrypted,
    nonce,
    passwordKey,
    rememberMe: false,
    version: getCurrentEncryptionVersion(),
  })

  if (savePayload.isErr()) {
    return mapErr(savePayload, 'Could not save personal keys')
  }

  const {
    privateKeyRecovery,
    privateKeyRecoveryNonce,
    recoveryKeyEncrypted,
    recoveryKeyEncryptedNonce,
    recoveryKeyPlain,
  } = createdRecoveryKey.value

  const mutationInput = {
    tokenType: TokenType.CreatePassword as const,
    name,
    passwordPrehash,
    nonce,
    salt,
    privateKeyEncrypted,
    publicKey,
    privateKeyRecovery,
    privateKeyRecoveryNonce,
    recoveryKeyEncrypted,
    recoveryKeyEncryptedNonce,

    ...(!isE2EE && { recoveryKeySystemEncrypted: systemRecoveryKey.value }),
  }

  return ok([mutationInput, isE2EE ? recoveryKeyPlain : null] as const)
}
