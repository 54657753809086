import { ok } from 'neverthrow'
import { prehashPassword } from '../password'
import { createSystemRecoveryKey, showRecoveryKey } from '../recovery'
import { mapErr } from '../utils/general'

type MigrationFromE2EEPayload = {
  systemPublicKey: string
  password: string
  salt: string
  recoveryKeyEncrypted: string
  recoveryKeyEncryptedNonce: string
}

export const processMigrationFromE2EE = async ({
  password,
  salt,
  recoveryKeyEncrypted,
  recoveryKeyEncryptedNonce,
  systemPublicKey,
}: MigrationFromE2EEPayload) => {
  const prehashedPassword = await prehashPassword({ password, salt })
  if (prehashedPassword.isErr()) {
    return mapErr(prehashedPassword, 'Could not prehash password')
  }

  const { passwordKey, passwordKeyPrehash } = prehashedPassword.value
  const recoveryKeyPayload = await showRecoveryKey(
    recoveryKeyEncrypted,
    recoveryKeyEncryptedNonce,
    passwordKey
  )

  if (recoveryKeyPayload.isErr()) {
    return mapErr(recoveryKeyPayload, 'Could not decrypt recovery key when migrating from E2EE')
  }

  const systemRecoveryKey = await createSystemRecoveryKey(recoveryKeyPayload.value, systemPublicKey)
  if (systemRecoveryKey.isErr()) {
    return mapErr(systemRecoveryKey, 'Could not create system recovery key')
  }

  return ok({
    currentPasswordPrehash: passwordKeyPrehash,
    newPasswordPrehash: passwordKeyPrehash,
    systemRecoveryKey: systemRecoveryKey.value,
    recoveryKeyEncrypted,
    recoveryKeyEncryptedNonce,
    newSalt: salt,
  })
}
