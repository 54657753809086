import type { UntitledIconData } from '@faceup/icons'

export const ucFileAi: UntitledIconData = {
  prefix: 'custom',
  name: 'ucFileAi',
  viewBox: '0 0 14 14',
  paths: [
    {
      shape:
        'M6.99836 1.16651C7.14829 1.16574 7.26554 1.26221 7.31398 1.43171C7.46737 1.96634 7.61154 2.50366 7.77377 3.0356C8.27854 4.69022 9.36919 5.75256 11.0242 6.23607C11.5293 6.38366 12.0364 6.52549 12.542 6.67115C12.7361 6.72727 12.8291 6.82874 12.8326 6.98094C12.8361 7.1366 12.7392 7.24499 12.5389 7.30302C11.9507 7.47329 11.354 7.61973 10.7747 7.81651C9.21234 8.34691 8.21049 9.42425 7.75032 11.0089C7.60077 11.5247 7.45776 12.0424 7.30937 12.5586C7.23363 12.8219 6.96645 12.9203 6.79345 12.745C6.74001 12.6904 6.70657 12.6078 6.68427 12.5321C6.51935 11.9709 6.37441 11.4033 6.19603 10.8467C5.69473 9.28396 4.65136 8.25967 3.08977 7.77116C2.55117 7.60243 2.0045 7.45868 1.46168 7.30379C1.26369 7.24652 1.16412 7.13967 1.16604 6.98286C1.16797 6.82643 1.26561 6.72727 1.46821 6.66808C2.10253 6.48398 2.74954 6.33446 3.36772 6.10654C4.85434 5.55769 5.80621 4.49266 6.24755 2.96949C6.39556 2.4583 6.53857 1.94558 6.68504 1.43401C6.73386 1.26298 6.84766 1.16728 6.99836 1.16651Z',
      fill: 'url(#paint0_linear_25996_94561)',
    },
  ],
  defs: {
    linearGradients: [
      {
        id: 'paint0_linear_25996_94561',
        x1: '4.04568',
        y1: '25.2898',
        x2: '20.7172',
        y2: '16.8499',
        gradientUnits: 'userSpaceOnUse',
        stops: [{ stopColor: '#0085FF' }, { offset: '1', stopColor: '#730099' }],
      },
    ],
  },
}
