import { useLocalStorage } from '@mantine/hooks'
import { useMemo } from 'react'
import type { AlertClosedFor, AlertKey } from '../hooks/useAlertOpen'
import { useGlobalInfo } from '../locales'
import type { ReportsFilterSchema } from './reportFilters'

export enum ContactSalesAlertState {
  Open = 'Open',
  ClosedOnce = 'ClosedOnce',
  ClosedPermanently = 'ClosedPermanently',
}

type State = {
  isDashboardReportPasswordAlertOpen: boolean | null
  contactSalesAlertState: ContactSalesAlertState
  wasRedactionOpened: boolean | null
  reportsWithOpenTranscript: string[]
  reportsFilters: ReportsFilterSchema
  statisticsFilters: ReportsFilterSchema
  alerts: Partial<Record<AlertKey, { closedFor: AlertClosedFor; isVisible: boolean }>>
}

export const usePersistentUserStorage = () => {
  const { viewerId } = useGlobalInfo()
  const userStorageKey = `${viewerId}_config`

  const [
    {
      contactSalesAlertState,
      reportsWithOpenTranscript,
      wasRedactionOpened,
      isDashboardReportPasswordAlertOpen,
      reportsFilters,
      statisticsFilters,
      alerts,
    },
    setState,
  ] = useLocalStorage<State>({
    key: userStorageKey,
    defaultValue: {
      isDashboardReportPasswordAlertOpen: true,
      contactSalesAlertState: ContactSalesAlertState.Open,
      wasRedactionOpened: false,
      reportsWithOpenTranscript: [],
      reportsFilters: {},
      statisticsFilters: {},
      alerts: {},
    },
  })

  return useMemo(
    () => ({
      isDashboardReportPasswordAlertOpen,
      setIsDashboardReportPasswordAlertOpen: (
        newValue: State['isDashboardReportPasswordAlertOpen']
      ) => {
        setState(currentValue => {
          return {
            ...currentValue,
            isDashboardReportPasswordAlertOpen: newValue,
          }
        })
      },
      contactSalesAlertState,
      setContactSalesAlertState: (newValue: State['contactSalesAlertState']) => {
        setState(currentValue => {
          return {
            ...currentValue,
            contactSalesAlertState: newValue,
          }
        })
      },
      wasRedactionOpened,
      setWasRedactionOpened: (newValue: State['wasRedactionOpened']) => {
        setState(currentValue => {
          return {
            ...currentValue,
            wasRedactionOpened: newValue,
          }
        })
      },
      reportsWithOpenTranscript,
      setReportsWithOpenTranscript: (
        newValue: (prev: State['reportsWithOpenTranscript']) => State['reportsWithOpenTranscript']
      ) => {
        setState(currentValue => {
          return {
            ...currentValue,
            reportsWithOpenTranscript: newValue(currentValue.reportsWithOpenTranscript),
          }
        })
      },
      reportsFilters,
      setReportsFilters: (newValue: State['reportsFilters']) => {
        setState(currentValue => {
          return {
            ...currentValue,
            reportsFilters: newValue,
          }
        })
      },
      statisticsFilters,
      setStatisticsFilters: (newValue: State['statisticsFilters']) => {
        setState(currentValue => {
          return {
            ...currentValue,
            statisticsFilters: newValue,
          }
        })
      },
      alerts: alerts ?? {},
      setAlerts: (newValue: State['alerts']) => {
        setState(currentValue => {
          return {
            ...currentValue,
            alerts: newValue,
          }
        })
      },
    }),
    [
      isDashboardReportPasswordAlertOpen,
      contactSalesAlertState,
      wasRedactionOpened,
      reportsWithOpenTranscript,
      reportsFilters,
      statisticsFilters,
      alerts,
      setState,
    ]
  )
}
