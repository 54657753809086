import { ok } from 'neverthrow'
import { fromBase64, toBase64, toText } from './atoms/convertors'
import { Symmetric } from './atoms/symmetric'
import { readReportKey } from './report'
import { mapErr } from './utils/general'

export const readClosingComment = async (
  ciphertext: string | null,
  nonce: string,
  recipientKey: string
) => {
  if (!ciphertext) {
    return ok('')
  }

  const reportKey = await readReportKey(recipientKey)
  if (reportKey.isErr()) {
    return mapErr(reportKey, 'Could not read report key when reading closing comment')
  }

  const decryptedText = await Symmetric.decrypt(
    Symmetric.toCiphertext(await fromBase64(ciphertext)),
    Symmetric.toKey(reportKey.value),
    Symmetric.toNonce(await fromBase64(nonce))
  )

  if (decryptedText.isErr()) {
    return mapErr(decryptedText, 'Cannot decrypt comment when reading closing comment')
  }

  return ok(await toText(decryptedText.value))
}

export const createClosingComment = async (text: string, recipientKey: string) => {
  const nonce = await Symmetric.generateNonce()
  const reportKey = await readReportKey(recipientKey)
  if (reportKey.isErr()) {
    return mapErr(reportKey, 'Could not read report key when creating closing comment')
  }

  const ciphertext = await Symmetric.encrypt(text, Symmetric.toKey(reportKey.value), nonce)

  if (ciphertext.isErr()) {
    return mapErr(ciphertext, 'Cannot encrypt when creating closing comment')
  }

  return ok({
    body: await toBase64(ciphertext.value),
    nonce: await toBase64(nonce),
  })
}
