import { useState } from 'react'
import { usePersistentUserStorage } from '../utils/persistentUserStorage'

export type AlertClosedFor = 'untilReload_shouldBeUntilNextLogin' | 'forever'
export type AlertKey = 'inTrial' | 'nonRenewing'

type AlertConfig = Record<
  AlertKey,
  {
    closedFor: AlertClosedFor
  }
>

type UseAlertOpenProps = {
  key: AlertKey
}

type UseAlertOpenReturn = {
  isVisible: boolean
  onClose: () => void
}

const alertConfig: AlertConfig = {
  inTrial: {
    closedFor: 'untilReload_shouldBeUntilNextLogin',
  },
  nonRenewing: {
    closedFor: 'forever',
  },
}

export const useAlertOpen = ({ key }: UseAlertOpenProps): UseAlertOpenReturn => {
  const config = alertConfig[key]
  const { alerts, setAlerts } = usePersistentUserStorage()
  const alert = alerts[key]
  const [tempAlerts, setTempAlerts] = useState<
    Partial<
      Record<
        AlertKey,
        {
          closedFor: AlertClosedFor
          isVisible: boolean
        }
      >
    >
  >({})
  const tempAlert = tempAlerts[key]

  if (config?.closedFor === 'untilReload_shouldBeUntilNextLogin') {
    return {
      isVisible: tempAlert?.isVisible ?? true,
      onClose: () => {
        setTempAlerts({
          ...tempAlerts,
          [key]: {
            closedFor: config.closedFor,
            isVisible: false,
          },
        })
      },
    }
  }

  return {
    isVisible: alert?.isVisible ?? true,
    onClose: () => {
      setAlerts({
        ...alerts,
        [key]: {
          closedFor: config.closedFor,
          isVisible: false,
        },
      })
    },
  }
}

export const useAlertReset = () => {
  const { alerts, setAlerts } = usePersistentUserStorage()

  return ({ closedFor }: { closedFor: AlertClosedFor }) => {
    const newAlerts = Object.entries(alerts).reduce<typeof alerts>((acc, [key, value]) => {
      if (value.closedFor !== closedFor) {
        acc[key as AlertKey] = value
      }
      return acc
    }, {})
    setAlerts(newAlerts)
  }
}
