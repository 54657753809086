import { ApolloClient, ApolloProvider, InMemoryCache, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import {
  DEFAULT_LANGUAGE,
  buildUrl,
  getApiUrlByCurrentDomain,
  regionHeaderName,
} from '@faceup/utils'
import * as Sentry from '@sentry/react'
// @ts-expect-error Ignore types https://github.com/jaydenseric/apollo-upload-client/releases/tag/v18.0.0#:~:text=Implemented%20TypeScript%20types%20via%20JSDoc%20comments.
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { print } from 'graphql/language/printer'
import { useContext } from 'react'
import { LanguageContext } from './Contexts/LanguageContext'
import type { StrictTypedTypePolicies } from './__generated__/apollo-helpers'
import { possibleTypes } from './__generated__/possibleTypes.json'
import { reset } from './utils/analytics'
import Auth from './utils/auth'
import { retrieveRegion } from './utils/useRegion'

const CustomApolloProvider = ({ children }: { children?: React.ReactNode }) => {
  const { language } = useContext(LanguageContext)

  // inspiration
  // > https://www.apollographql.com/docs/react/advanced/subscriptions.html
  // + file upload https://github.com/jaydenseric/apollo-upload-client#function-createuploadlink
  const httpLink = createUploadLink({
    uri: getApiUrlByCurrentDomain(import.meta.env.VITE_API_URL ?? ''),
    credentials: 'include',
  })

  const wsLink = new WebSocketLink({
    uri: buildUrl(
      getApiUrlByCurrentDomain(import.meta.env.VITE_WS_API_URL ?? ''),
      retrieveRegion() ? { [regionHeaderName]: retrieveRegion() as string } : {}
    ),
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: () => ({
        token: Auth.getJwt() || '',
        language: language || DEFAULT_LANGUAGE,
        origin: window.location.origin,
        ...(retrieveRegion() && { [regionHeaderName]: retrieveRegion() }),
      }),
    },
  })

  const errorLink = onError(({ operation, networkError, graphQLErrors }) => {
    if (graphQLErrors?.some(error => error?.message === 'Outdated or corrupted JWT token')) {
      apolloClient.stop()
      apolloClient.clearStore()
      reset()
      Auth.logout()
      window.location.replace('/')
    }

    if (graphQLErrors) {
      Sentry.captureException(`[GQL error]: ${operation.operationName}`, {
        extra: {
          query: print(operation.query),
          variables: operation.variables,
          ...graphQLErrors.reduce(
            (acc, error) => ({
              ...acc,
              [(error as unknown as { name: string }).name]: error.message,
            }),
            {}
          ),
        },
      })
    }

    if (networkError) {
      Sentry.captureException(`[Network error]: ${operation.operationName}`, {
        extra: {
          query: print(operation.query),
          variables: operation.variables,
          message: networkError.message,
        },
      })
    }
  })

  const authLink = setContext((_, { headers }) => {
    const token = Auth.getJwt()

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        language,
        ...(retrieveRegion() && { [regionHeaderName]: retrieveRegion() }),
      },
    }
  })

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)

      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    errorLink.concat(authLink.concat(httpLink))
  )

  const typePolicies: StrictTypedTypePolicies = {
    CompanyReport: {
      fields: {
        assignedMembers: {
          merge: (_existing, incoming) => incoming,
        },
      },
    },
    CompanyConfig: {
      fields: {
        labels: {
          merge: (_existing, incoming) => incoming,
        },
        reportCategoriesTranslations: {
          merge: (_existing, incoming) => incoming,
        },
      },
    },
    ReportSource: {
      fields: {
        categories: {
          merge: (_existing, incoming) => incoming,
        },
      },
    },
    Member: {
      fields: {
        partner: {
          merge: (_existing, incoming) => incoming,
        },
      },
    },
  }

  const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache({
      possibleTypes,
      // https://medium.com/@dexiouz/fix-cache-data-may-be-lost-when-replacing-the-getallposts-field-of-a-query-object-in-apollo-client-7973a87a1b43
      typePolicies,
    }),
  })

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default CustomApolloProvider
